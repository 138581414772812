import React from 'react';
import shallow from "zustand/shallow";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { SimpleSelect } from 'components/FormSelect';
import { QuestionValidationSchema, QuestionTypes } from "./constants";
import ContentEditable from 'components/forms/SEPTemplateEditor/ContentEditable';
import CustomChipArray from 'components/CustomChipArray';
import useEditorStore from './editorStore';
import FieldSet from './FieldSet';
import "./styles.css";
import useField from './useField';

function Field({ index, readOnly, name, question = {}, errors = {}, ...props }) {
    const { handleChange, config } = useField({ index, name });

    return (
        <FieldSet readOnly={readOnly} label={config.label} error={errors?.[name]}>
            <input
                className="question-input"
                onChange={handleChange}
                name={name}
                value={question[name]}
                placeholder={config.placeholder}
                {...props}
            />
        </FieldSet>
    );
}

function MCQField({ index, name, readOnly, question, errors }) {
    const { handleChange, config } = useField({ index, name });

    return (
        <CustomChipArray
            InputProps={{ name, placeholder: config.placeholder }}
            onValuesChange={(values) => handleChange({ target: { name, value: values } })}
            label={config.label}
            readOnly={readOnly}
            error={errors?.[name]}
            initialValues={question?.[name]}
        />
    );
}

function SelectField({ index, name, readOnly, question, errors }) {
    const { handleChange, config } = useField({ index, name });

    return (
        <SimpleSelect
            readOnly={readOnly}
            label={config.label}
            id="third-create-interview-tip"
            options={QuestionTypes}
            name={name}
            onChange={handleChange}
            value={question?.[name] || ""}
            error={Boolean(errors?.[name])}
            helperText={errors?.[name]}
        />
    );
}

function Question({ readOnly, index }) {
    const validate = useEditorStore(state => state.validate);
    const deleteQuestion = useEditorStore(state => state.deleteQuestion);
    const moveQuestion = useEditorStore(state => state.moveQuestion); 
    const type = useEditorStore(state => state.questions?.[index]?.['type'], shallow);
    const question = useEditorStore(state => state.questions?.[index], shallow);
    const errors = useEditorStore(state => state.errors?.[index], shallow);
    const addQuestionError = useEditorStore(state => state.addQuestionError, shallow);
    const questions = useEditorStore(state => state.questions, shallow);

    React.useEffect(() => {
        if (!validate) return;

        QuestionValidationSchema
            .validate(question, { abortEarly: false })
            .then(values => addQuestionError(index, null))
            .catch((err) => {
                const errors = err?.inner?.reduce((acc, error) => ({
                    ...acc, [error.path]: error.message
                }), {})

                if (errors) addQuestionError(index, errors);
            });
    }, [validate, question, addQuestionError, index]);

    return (
        <Box
            display="flex"
            alignItems="stretch"
            py={1}
            backgroundColor="#F4F5F5"
            my={1}
            pr={2}
            pl={2}
        >
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <ContentEditable
                    index={index}
                    readOnly={readOnly}
                    question={question}
                    errors={errors}
                    defaultValue={question?.question || ""}
                />

                <Field
                    index={index}
                    name="hint"
                    readOnly={readOnly}
                    question={question}
                    errors={errors}
                />
                {['mcq', 'scq'].includes(type) && (
                    <>
                        <MCQField
                            index={index}
                            name="options"
                            readOnly={readOnly}
                            question={question}
                            errors={errors}
                        />
                        <MCQField
                            index={index}
                            name="answers"
                            readOnly={readOnly}
                            question={question}
                            errors={errors}
                        />
                    </>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                ml={2}
                width={150}
                position="relative"
            >
                <IconButton
                    disabled={index === 0 || !!readOnly}
                    onClick={() => moveQuestion(index, index - 1)}
                >
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                    disabled={index === questions.length - 1 || !!readOnly}
                    onClick={() => moveQuestion(index, index + 1)}
                >
                    <ArrowDownwardIcon />
                </IconButton>

                <IconButton
                    disabled={!!readOnly}
                    onClick={() => deleteQuestion(index)}
                    sx={{ height: "fit-content", alignSelf: "flex-end" }}
                >
                    <DeleteOutlinedIcon
                        className="secondary"
                        color="error"
                        sx={{ cursor: "pointer" }}
                    />
                </IconButton>

                <SelectField
                    index={index}
                    name={"type"}
                    question={question}
                    errors={errors}
                />
                <Field
                    id="fifth-create-interview-tip"
                    name="allottedTime"
                    type="number"
                    index={index}
                    readOnly={readOnly}
                    question={question}
                    errors={errors}
                />
            </Box>
        </Box>
    );
}

function QuestionSection({
    readOnly,
}) {
    const length = useEditorStore(state => state.length);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
                height: "80vh",
                flexGrow: 1,
                overflowY: "auto",
            }}
        >
            {new Array(length).fill(0).map((x, index) => (
                <Question key={index} index={index} readOnly={readOnly} />
            ))}
        </div>
    );
}

export default QuestionSection;
