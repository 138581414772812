import React from 'react';
import shallow from "zustand/shallow";
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { SimpleTextField } from 'components/FormTextField';
import ClientSelect from 'components/ClientSelect';
import useEditorStore from './editorStore';
import AddButton from './AddButton';
import { TemplateValidationSchema } from './constants';
import { useLoading, useSnackbar } from 'contexts';
import {
    saveSEPTemplate,
} from 'services/sepTemplates'
import { CheckboxOptions } from 'components/FormSelect';

function TemplateDetails(props) {
    const ref = React.useRef();

    const location = useLocation();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const loading = useLoading();

    const [save, setSave] = React.useState(false);

    const [validate, setValidate, template, updateTemplate, creator, errors,
        setTemplateError, getData, setTemplate, setCreator
    ] = useEditorStore(s => [
        s.validate, s.setValidate, s.template, s.updateTemplate, s.creator,
        s.errors, s.setTemplateError, s.getData, s.setTemplate, s.setCreator
    ], shallow);

    const handleSave = async () => {
        setValidate(true);
        setSave(true);
        setTimeout(() => setValidate(false), 100);
    };

    React.useEffect(() => {
        if (!validate) return;

        TemplateValidationSchema
            .validate(template, { abortEarly: false })
            .then(values => setTemplateError(null))
            .catch((err) => {
                const errors = err.inner.reduce((acc, error) => ({
                    ...acc, [error.path]: error.message
                }), {})

                setTemplateError(errors);
            })
    }, [validate, template, setTemplateError]);

    React.useEffect(() => {
        if (ref.current) {
            if (!Object.values(errors).find(x => !!x === true)) {
                if (!save) return;

                (async () => {
                    try {
                        loading.show();

                        const { template, questions } = getData();

                        const savedTemplate = await saveSEPTemplate(
                            template._id, {
                            ...template,
                            questions: questions.map((q, index) => ({
                                ...q,
                                serialNumber: index + 1,
                                allottedTime: q.allottedTime * 60,
                            })),
                            sendEmailReport: template?.sendEmailReport || false
                        }
                        );

                        snackbar.showSnackbar("Template saved successfully", "success");

                        setTemplate(savedTemplate);

                        setCreator(true);

                        navigate(location.pathname.replace("create", savedTemplate._id));
                    } catch (error) {
                        console.log(error);
                        snackbar.showSnackbar("Uh Oh! Unable to save the template", "error");
                    } finally {
                        loading.hide();
                    }

                })();
                setSave(false);
            } else {
                snackbar.showSnackbar("Please provide valid data!", "error");
            }
        } else {
            ref.current = true;
        }
    }, [errors, save]);

    return (
        <Box display="flex" flexDirection="column" minWidth={250}>
            <SimpleTextField
                label="Template Name*"
                fullWidth
                placeholder="e.g. Product Management Advanced"
                variant="outlined"
                name="name"
                value={template?.name || ""}
                onChange={updateTemplate}
                inputProps={{ readOnly: !creator }}
                error={errors?.template?.name && Boolean(errors?.template?.name)}
                helperText={errors?.template?.name}
            />
            <ClientSelect
                sepClients
                defaultValue={template.user || ""}
                onChange={(client) => updateTemplate({
                    target: { name: 'user', value: client._id }
                })}
                error={errors?.template?.user}
            />

            <CheckboxOptions
              name='module type'
              options={['Enable Proctoring']}
              value={template?.proctorSettings?.generalProctoring ? 'Enable Proctoring' : false}
              onChange={(e) => {
                updateTemplate({
                    target: { 
                      name: 'proctorSettings', value: {
                        ...template?.proctorSettings,
                        generalProctoring: template?.proctorSettings?.generalProctoring ? false : true
                      }
                    }
                });
              }}
            />

            <CheckboxOptions
                name='email report'
                options={['Send Report Email']}
                value={template?.sendEmailReport ? 'Send Report Email' : false}
                onChange={(e) => {
                    updateTemplate({
                    target: { 
                        name: 'sendEmailReport', 
                        value: !template?.sendEmailReport 
                    }
                    });
                }}
            />


            <Box display="flex" alignItems={"end"} mt={1} justifyContent="flex-end">
                {creator && <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    disabled={props?.questionsLength === 0}
                    onClick={handleSave}
                >
                    Save Template
                </Button>}
                &nbsp;&nbsp;
                <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Box>
            <br />
            <AddButton />
        </Box >
    );
}

export default TemplateDetails;