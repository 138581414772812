import create from "zustand";

export const DefaultQuestion = {
  question: "",
  type: "",
  options: [],
  answers: [],
  hint: "",
  allottedTime: 1,
};

const defaults = {
  validate: false,
  creator: true,
  length: 0,
  data: null,
  template: {
    name: "",
    user: "",
    proctorSettings: {
      generalProctoring: false
    }
  },
  questions: [],
  errors: {},
};

const useEditorStore = create(
  (set, get) => ({
    ...defaults,
    getData: () => ({
      template: get().template,
      questions: get().questions,
    }),
    setValidate: (validate) => set({ validate }),
    setCreator: (creator) => set({ creator }),
    setLength: (length) => set({ length }),
    setTemplate: (template) => set({ template }),
    updateTemplate: (event) => set((state) => ({
      template: { ...state.template, [event.target.name]: event.target.value }
    })),
    setQuestions: (questions) => set({ questions, length: questions.length }),
    deleteQuestion: (index) => set((s) => ({
      length: s.length - 1,
      questions: [
        ...s.questions.slice(0, index),
        ...s.questions.slice(index + 1)
      ]
    })),
    addQuestion: () => set((state) => ({
      questions: [...state.questions, DefaultQuestion],
      length: state.length + 1
    })),
    updateQuestion: async (index, event) => {
      set(({ questions }) => ({
        questions: [
          ...questions.slice(0, index),
          { ...questions[index], [event.target.name]: event.target.value },
          ...questions.slice(index + 1)
        ]
      }))
    },
    setQuestionsError: (questionsError) => set({ questionsError }),
    addQuestionError: (index, error) => set((state) => ({
      errors: { ...state.errors, [index]: error }
    })),
    setTemplateError: (error) => set((state) => ({
      errors: { ...state.errors, template: error }
    })),
    reset: () => set(defaults),
    moveQuestion: (fromIndex, toIndex) => set((state) => {
      const questions = [...state.questions];
      if (fromIndex >= 0 && toIndex >= 0 && toIndex < questions.length) {
        const [movedQuestion] = questions.splice(fromIndex, 1);
        questions.splice(toIndex, 0, movedQuestion);
        return { questions };
      }
      return state; 
    }),
  }),
);

export default useEditorStore;